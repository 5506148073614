<template>
  <v-container class="grey lighten-5">
    <button @click="getPDF">Download PDF</button>
    <div v-if="vocommissioning" class="container" id="content-div">

      <!-- Engineer & Job Details + Customer Site & Contact Details -->
      <div class="two-columns">

        <!-- Engineer & Job Details -->
        <div>
          <div class="section-title">Engineer & Job Details</div>
          <table style="width: 100%; border-collapse: collapse;">
            <tr style="background-color: #f2f2f2;">
              <td style="padding: 8px; border: 1px solid #ddd;"><strong>Engineer Name</strong></td>
              <td style="padding: 8px; border: 1px solid #ddd;">{{vocommissioning.engineerName}}</td>
            </tr>
            <tr>
              <td style="padding: 8px; border: 1px solid #ddd;"><strong>Job Number</strong></td>
              <td style="padding: 8px; border: 1px solid #ddd;">{{vocommissioning.jobNumber}}</td>
            </tr>
            <tr style="background-color: #f2f2f2;">
              <td style="padding: 8px; border: 1px solid #ddd;"><strong>Site ID</strong></td>
              <td style="padding: 8px; border: 1px solid #ddd;">{{vocommissioning.siteId}}</td>
            </tr>
            <tr>
              <td style="padding: 8px; border: 1px solid #ddd;"><strong>Work Date</strong></td>
              <td style="padding: 8px; border: 1px solid #ddd;">{{vocommissioning.workDate}}</td>
            </tr>
          </table>
        </div>


        <!-- Customer Site & Contact Details -->
        <div>
          <div class="section-title">Customer Site & Contact Details</div>
          <table style="width: 100%; border-collapse: collapse;">
            <tr style="background-color: #f2f2f2;">
              <td style="padding: 8px; border: 1px solid #ddd;"><strong>Customer Name</strong></td>
              <td style="padding: 8px; border: 1px solid #ddd;">{{vocommissioning.customerName}}</td>
            </tr>
            <tr>
              <td style="padding: 8px; border: 1px solid #ddd;"><strong>Site Address</strong></td>
              <td style="padding: 8px; border: 1px solid #ddd;">{{vocommissioning.metadata.siteAddress1}} - {{vocommissioning.metadata.sitePostcode}}</td>
            </tr>
            <tr style="background-color: #f2f2f2;">
              <td style="padding: 8px; border: 1px solid #ddd;"><strong>Main Contact Name:</strong></td>
              <td style="padding: 8px; border: 1px solid #ddd;">{{vocommissioning.metadata.mainContactName}}</td>
            </tr>
            <tr>
              <td style="padding: 8px; border: 1px solid #ddd;"><strong>Main Contact Number:</strong></td>
              <td style="padding: 8px; border: 1px solid #ddd;">{{vocommissioning.metadata.mainContactNumber}}</td>
            </tr>
          </table>
        </div>

      </div>

      <div v-for="(currentEquipment, index) in vocommissioning.metadata.equipmentDetails" :key="index">
        <h3>Equipment {{ index + 1 }}</h3>


      <!-- Equipment Details (repeating section) -->
      <div class="section-title">Equipment Details</div>
      <div class="equipment-details">
        <!-- Textual Information - Two Column Layout -->
        <div class="details-columns">
          <div class="left-column">
            <p><strong>Manufacturer:</strong> {{currentEquipment.manufacturer}}</p>
            <p><strong>Model:</strong> {{currentEquipment.model}}</p>
            <p><strong>VO Rating:</strong> {{currentEquipment.voRating}}</p>
            <p><strong>IS Voltage Tap:</strong> {{currentEquipment.isVoltageTap}}</p>
            <p><strong>Serial Number:</strong> {{currentEquipment.serialNumber}}</p>
            <p><strong>Main Incommer:</strong> {{currentEquipment.installationMainIncommer}}</p>
            <p><strong>Main Incommer Device Rating:</strong> {{currentEquipment.installationIncommerDeviceRating}}</p>
            <p><strong>Main Incommer Device Ir:</strong> {{currentEquipment.installationIncommerDeviceIrSetting}}</p>
            <p><strong>Main Incommer Device Ii:</strong> {{currentEquipment.installationIncommerDeviceIiSetting}}</p>
            <p><strong>Main Incommer Device Tr:</strong> {{currentEquipment.installationIncommerDeviceTrSetting}}</p>
          </div>
          <div class="right-column">
            <p><strong>IS MCCB Installed:</strong> {{currentEquipment.isMccbInstalled}}</p>
            <p><strong>MCCB Rating:</strong> {{currentEquipment.mccbRating}}</p>
            <p><strong>MCCB IR Setting:</strong> {{currentEquipment.mccbIrSetting}}</p>
            <p><strong>MCCB II Setting:</strong> {{currentEquipment.mccbIiSetting}}</p>
            <p><strong>MCCB TR Setting:</strong> {{currentEquipment.mccbTrSetting}}</p>
            <p><strong>Outgoing Device:</strong> {{currentEquipment.outgoingDevice}}</p>
            <p><strong>Junction Box Installed:</strong> {{currentEquipment.junctionBoxInstalled}}</p>
            <p><strong>Bypass Panel Installed:</strong> {{currentEquipment.bypassPanelInstalled}}</p>
            <p><strong>Notes:</strong> {{currentEquipment.notes}}</p>
          </div>
        </div>
      </div>


        <div class="section-title">Mechanical Checks</div>
        <div class="details-columns">
          <div class="left-column">
            <p><strong>Power Connections Checked:</strong> {{currentEquipment.powerConnectionsChecked}}</p>
            <p><strong>VO Seated Correctly:</strong> {{currentEquipment.voSeatedCorrectly}}</p>
            <p><strong>Cable Installed To BS7671:</strong> {{currentEquipment.cableInstallToBS7671}}</p>
            <p><strong>IS A Meter Installed:</strong> {{currentEquipment.isAMeterInstalled}}</p>

          </div>
          <div class="right-column">
            <p><strong>Metering CTs checked?:</strong> {{currentEquipment.meteringCTsChecked}}</p>
            <p><strong>Meter Operating Correctly?:</strong> {{currentEquipment.meterOperatingCorrectly}}</p>
            <p><strong>NICEIC Form Complete:</strong> {{currentEquipment.niceicFormCompleted}}</p>
          </div>
        </div>


        <div class="section-title">Environment Checks</div>
        <div class="details-columns">
          <div class="left-column">
            <p><strong>Sufficient Front and Rear Clearance</strong> {{currentEquipment.sufficientFrontAndRearClearance}}</p>
            <p><strong>InstallationInstructionsRespected:</strong> {{currentEquipment.installationInstructionsRespected}}</p>
          </div>
          <div class="right-column">
            <p><strong>Correct Equipment Earthing:</strong> {{currentEquipment.correctEquipmentEarthing}}</p>
          </div>
        </div>

        <div class="section-title">Primary Electrical Checks</div>
        <div class="details-columns">
          <div class="left-column">
            <p><strong>Is the Phase Rotation Correct</strong> {{currentEquipment.mainsRotationCorrect}}</p>
            <p><strong>Supply N-1:</strong> {{currentEquipment.supplyN1}}</p>
            <p><strong>Supply N-2:</strong> {{currentEquipment.supplyN2}}</p>
            <p><strong>Supply N-3:</strong> {{currentEquipment.supplyN3}}</p>
            <p><strong>Supply 1-2:</strong> {{currentEquipment.supply12}}</p>
            <p><strong>Supply 2-3:</strong> {{currentEquipment.supply23}}</p>
            <p><strong>Supply 3-1:</strong> {{currentEquipment.supply31}}</p>
            <p><strong>Supply I-1:</strong> {{currentEquipment.supplyI1}}</p>
            <p><strong>Supply I-2:</strong> {{currentEquipment.supplyI2}}</p>
            <p><strong>Supply I-3:</strong> {{currentEquipment.supplyI3}}</p>
            <p><strong>Supply Freq:</strong> {{currentEquipment.supplyFreq}}</p>
            <p><strong>Output N-1:</strong> {{currentEquipment.outputN1}}</p>

          </div>
          <div class="right-column">
            <p><strong>Output N-2:</strong> {{currentEquipment.outputN2}}</p>
            <p><strong>Output N-3:</strong> {{currentEquipment.outputN3}}</p>
            <p><strong>Output 1-2:</strong> {{currentEquipment.output12}}</p>
            <p><strong>Output 2-3:</strong> {{currentEquipment.output23}}</p>
            <p><strong>Output 3-1:</strong> {{currentEquipment.output31}}</p>
            <p><strong>Output I-1:</strong> {{currentEquipment.outputI1}}</p>
            <p><strong>Output I-2:</strong> {{currentEquipment.outputI2}}</p>
            <p><strong>Output I-3:</strong> {{currentEquipment.outputI3}}</p>
            <p><strong>Output Freq:</strong> {{currentEquipment.outputFreq}}</p>
            <p><strong>Volatage Near:</strong> {{currentEquipment.voltageNear}}</p>
            <p><strong>Volatage Far:</strong> {{currentEquipment.voltageFar}}</p>
          </div>

        </div>

        <div v-if="currentEquipment.sldDiagrams && currentEquipment.sldDiagrams.length">
          <div class="sld-diagrams">
            <div class="section-title">SLD Diagram Flow</div>
            <table width="100%">
              <thead>
              <tr>
                <th>Earth</th>
                <th>Phase</th>
                <th>Device</th>
                <th>Neutral</th>
                <th>Setting</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="diagram in currentEquipment.sldDiagrams">
                <td>{{ diagram.sldFlowEarth }}</td>
                <td>{{ diagram.sldFlowPhase }}</td>
                <td>{{ diagram.sldFlowDevice }}</td>
                <td>{{ diagram.sldFlowNeutral }}</td>
                <td>{{ diagram.sldFlowSetting }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>



        <!-- Photos - Grid Layout -->
        <div class="section-title">Photos</div>


        <div class="photo-grid">
          <!-- Installation Main Incommer Photo -->
          <div class="photo-item">
            <template v-if="currentEquipment.installationMainIncommerPhoto && currentEquipment.installationMainIncommerPhoto.assetFilename">
              <img :src="'/api/attachments/'+vocommissioning.metadata.instanceID.replace('uuid:', '')+'/'+currentEquipment.installationMainIncommerPhoto.assetFilename" alt="Installation Main Incommer Photo" @click="openInNewTab('/api/attachments/'+vocommissioning.metadata.instanceID.replace('uuid:', '')+'/'+currentEquipment.installationMainIncommerPhoto.assetFilename)">
            </template>
            <div v-else class="placeholder">No Photo</div>
          </div>

          <!-- Bypass Panel Photo -->
          <div class="photo-item">
            <template v-if="currentEquipment.bypassPanelPhoto && currentEquipment.bypassPanelPhoto.assetFilename">
              <img :src="'/api/attachments/'+vocommissioning.metadata.instanceID.replace('uuid:', '')+'/'+currentEquipment.bypassPanelPhoto.assetFilename" alt="Bypass Panel Photo" @click="openInNewTab('/api/attachments/'+vocommissioning.metadata.instanceID.replace('uuid:', '')+'/'+currentEquipment.bypassPanelPhoto.assetFilename)">
            </template>
            <div v-else class="placeholder">No Photo</div>
          </div>

          <!-- MCCB Settings Photo -->
          <div class="photo-item">
            <template v-if="currentEquipment.mccbSettingsPhoto && currentEquipment.mccbSettingsPhoto.assetFilename">
              <img :src="'/api/attachments/'+vocommissioning.metadata.instanceID.replace('uuid:', '')+'/'+currentEquipment.mccbSettingsPhoto.assetFilename" alt="MCCB Settings Photo" @click="openInNewTab('/api/attachments/'+vocommissioning.metadata.instanceID.replace('uuid:', '')+'/'+currentEquipment.mccbSettingsPhoto.assetFilename)">
            </template>
            <div v-else class="placeholder">No Photo</div>
          </div>

          <!-- Install Photo -->
          <div class="photo-item">
            <template v-if="currentEquipment.installPhoto && currentEquipment.installPhoto.assetFilename">
              <img :src="'/api/attachments/'+vocommissioning.metadata.instanceID.replace('uuid:', '')+'/'+currentEquipment.installPhoto.assetFilename" alt="Install Photo" @click="openInNewTab('http://127.0.0.1:3040/attachments/'+vocommissioning.metadata.instanceID.replace('uuid:', '')+'/'+currentEquipment.installPhoto.assetFilename)">
            </template>
            <div v-else class="placeholder">No Photo</div>
          </div>

          <!-- VO Photo -->
          <div class="photo-item">
            <template v-if="currentEquipment.VO && currentEquipment.VO.assetFilename">
              <img :src="'/api/attachments/'+vocommissioning.metadata.instanceID.replace('uuid:', '')+'/'+currentEquipment.VO.assetFilename" alt="VO Photo" @click="openInNewTab('/api/attachments/'+vocommissioning.metadata.instanceID.replace('uuid:', '')+'/'+currentEquipment.VO.assetFilename)">
            </template>
            <div v-else class="placeholder">No Photo</div>
          </div>

          <!-- MCCB General Photo -->
          <div class="photo-item">
            <template v-if="currentEquipment.mccbGeneralPhoto && currentEquipment.mccbGeneralPhoto.assetFilename">
              <img :src="'/api/attachments/'+vocommissioning.metadata.instanceID.replace('uuid:', '')+'/'+currentEquipment.mccbGeneralPhoto.assetFilename" alt="MCCB General Photo" @click="openInNewTab('/api/attachments/'+vocommissioning.metadata.instanceID.replace('uuid:', '')+'/'+currentEquipment.mccbGeneralPhoto.assetFilename)">
            </template>
            <div v-else class="placeholder">No Photo</div>
          </div>

          <!-- Junction Box Photo -->
          <div class="photo-item">
            <template v-if="currentEquipment.junctionBoxPhoto && currentEquipment.junctionBoxPhoto.assetFilename">
              <img :src="'/api/attachments/'+vocommissioning.metadata.instanceID.replace('uuid:', '')+'/'+currentEquipment.junctionBoxPhoto.assetFilename" alt="Junction Box Photo" @click="openInNewTab('/api/attachments/'+vocommissioning.metadata.instanceID.replace('uuid:', '')+'/'+currentEquipment.junctionBoxPhoto.assetFilename)">
            </template>
            <div v-else class="placeholder">No Photo</div>
          </div>


          <!-- Photo Of Voltage Tap -->
          <div class="photo-item">
            <template v-if="currentEquipment.photoOfVoltageTap && currentEquipment.photoOfVoltageTap.assetFilename">
              <img :src="'/api/attachments/'+vocommissioning.metadata.instanceID.replace('uuid:', '')+'/'+currentEquipment.photoOfVoltageTap.assetFilename" alt="Photo Of Voltage Tap" @click="openInNewTab('/api/attachments/'+vocommissioning.metadata.instanceID.replace('uuid:', '')+'/'+currentEquipment.photoOfVoltageTap.assetFilename)">
            </template>
            <div v-else class="placeholder">No Photo</div>
          </div>
        </div>

      </div>
      <!-- End of Single Equipment Detail -->
      <!-- Add more equipment details based on the iterator -->
    </div>
  </v-container>
</template>
<script>
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'View VO Commissioning Form'
  },
  data() {
    return {
      vocommissioning: null,
      tab: 0,
      model: 0,
      photoProps: [
        'controlConnectionPointPhoto',
        'powerConnectionPointPhoto',
        'coldSensorPositionPhoto',
        'returnAirSensorPositionPhoto',
        'generalInstallationPhoto',
        'photos2Installation',
        'photos2ThermostatController'
      ],
    }
  },
  computed: {
  },
  methods: {
    getVocommissioning() {
      if (this.$route.params.id !== null) {
        this.$store.dispatch('vocommissionings/get', this.$route.params.id).then((result) => {
              this.vocommissioning = result;
            }
        );
      }
    },
    openInNewTab(url) {
      window.open(url, '_blank');
    },
    getPDF() {
      const element = document.querySelector('#content-div');

      html2canvas(element, {
        scrollY: -window.scrollY,
        useCORS: true,
        scale: 1
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        // Calculate the scaling factor and the number of pages
        const canvasRatio = canvas.width / canvas.height;
        const pdfRatio = pdfWidth / pdfHeight;
        let scale;

        if (canvasRatio > pdfRatio) {
          // If canvas is wider than PDF page ratio
          scale = pdfWidth / canvas.width;
        } else {
          // If canvas is taller than PDF page ratio
          scale = pdfHeight / canvas.height;
        }

        const scaledWidth = canvas.width * scale;
        const scaledHeight = canvas.height * scale;
        const pages = Math.ceil(scaledHeight / pdfHeight);

        for (let i = 0; i < pages; i++) {
          if (i > 0) {
            pdf.addPage();
          }

          // These arguments define the portion of the source image to draw
          const sx = 0;
          const sy = (canvas.height / pages) * i;
          const sWidth = canvas.width;
          const sHeight = canvas.height / pages;

          // These arguments define the position and size on the pdf
          const dx = 0;
          const dy = 0;
          const dWidth = scaledWidth;
          const dHeight = scaledHeight / pages;

          pdf.addImage(imgData, 'PNG', dx, dy, dWidth, dHeight, null, 'FAST', sx, sy, sWidth, sHeight);
        }

        pdf.save('download.pdf');
      });
    }
  },
  mounted() {
    this.getVocommissioning();
  }
}
</script>
<style>
dl {
  padding: 0.5em;
}
dt {
  float: left;
  clear: left;
  width: 400px;
  text-align: right;
  font-weight: bold;
  color: green;
}
dt::after {
  content: ": ";
}
dd {
  margin: 0 0 0 110px;
  padding: 0 0 0.7em 0;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

body {
  font-family: Arial, sans-serif;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.section-title {
  font-weight: bold;
  background-color: #e0e0e0;
  padding: 5px;
  margin-top: 20px;
}

.two-columns {
  display: flex;
  justify-content: space-between;
}

.two-columns > div {
  width: 48%; /* Slight reduction to account for any possible box-model issues */
}

/* Print specific styles */
@media print {
  body {
    font-size: 12px;
  }
  .container {
    width: 100%;
    margin: 0;
    padding: 10mm;
  }
}

.equipment-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.details-columns {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.left-column, .right-column {
  flex: 1;
  padding: 15px;
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.photo-item {
  border: 1px solid #e0e0e0;
  overflow: hidden;
  min-height: 100px;
}

.photo-item img {
  max-width: 100%;
  display: block;
}

.placeholder {
  background-color: grey;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;  /* You can adjust this as per your design preference */
}
strong {
  font-weight: bold;
}

</style>
